<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'BlankAppBar',
};
</script>
